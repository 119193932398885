<template>
    <div class="design-system-v2">
        <h1>COMPONENTS/UI</h1>
        <section class="negative" >
            <h2>AppVersion.vue</h2>
            <AppVersion />
        </section>
        <section class="negative">
            <h2>Button.vue</h2>
            <div class="buttons">
                <p>normal</p>
                <Button bType="normal"></Button>
                <Button bType="normal" bLabel="library.upload_link" customClass="btn-secondary"></Button>

                <p>add_more (0 usos)</p>
                <Button bType="add_more"></Button>

                <p>filter</p>
                <Button bType="filter" bLabel="assets.filter.filter"></Button>
                <Button bType="filter" bLabel="assets.filter.refresh"></Button>

                <p>settings</p>
                <Button bType="settings" customClass="report"></Button>

                <p>share (0 usos)</p>
                <Button bType="share"></Button>

                <p>upload</p>
                <Button bType="upload" bLabel="library.upload_image" customClass="btn-clear"></Button>
                <Button bType="upload" bLabel="library.add_miniature"></Button>

                <p>print</p>
                <Button bType="print" bLabel="nvgt.report.print"></Button>

                <p>file</p>
                <Button bType="file"></Button>

                <p>delete</p>
                <Button bType="delete"></Button>

                <p>edit</p>
                <Button bType="edit"></Button>
                <Button bType="edit" customClass="btn-action" bCallbackProps="save" bLabel="template.general.button_save"></Button>
                <Button bType="edit" bLabel="product.change_icon" customClass="btn-secondary"></Button>

                <p>editOn (0 usos)</p>
                <Button bType="editOn"></Button>

                <p>editOn (0 usos)</p>
                <Button bType="editOff"></Button>

                <p>copy (0 usos)</p>
                <Button bType="copy"></Button>

                <p>duplicate</p>
                <Button bType="duplicate"></Button>
                <Button bType="duplicate" customClass="btn-duplicate" bLabel="menu_aux.action.clone"></Button>

                <p>convert</p>
                <Button bType="convert" customClass="btn-convert" bLabel="template.general.button_convert"></Button>

                <p>active (0 usos)</p>
                <Button bType="active"></Button>

                <p>inactive (0 usos)</p>
                <Button bType="inactive"></Button>

                <p>send</p>
                <Button bType="send" bLabel="user.form.send_invitation" customClass="btn-clear user-invitation"></Button>
                <Button bType="send" bLabel="user.form.send_invitation" customClass="btn-clear user-invitation disabled"></Button>

                <p>create</p>
                <Button bType="create"></Button>

                <p>cancel</p>
                <Button bType="cancel"></Button>

                <p>clearFilters</p>
                <Button bType="clearFilters"></Button>

                <p>cancel_changes</p>
                <Button bType="cancel_changes"></Button>

                <p>save</p>
                <Button bType="save"></Button>
                <Button bType="save" customClass="btn-action"></Button>

                <p>refresh</p>
                <Button bType="refresh"></Button>
                <Button bType="refresh" customClass="btn-secondary btn-clear"></Button>

                <p>back</p>
                <Button bType="back"></Button>

                <p>more</p>
                <Button bType="more"></Button>

                <p>accessLocation</p>
                <Button bType="accessLocation"></Button>

                <p>editIcon</p>
                <Button bType="editIcon"></Button>

                <p>showOrHideFilters</p>
                <Button bType="showOrHideFilters"></Button>

                <p>createIcon</p>
                <Button bType="createIcon"></Button>

                <p>savePrimary</p>
                <Button bType="savePrimary"></Button>

                <p>leftArrow (0 usos)</p>
                <Button bType="leftArrow"></Button>

                <p>rightArrow (0 usos)</p>
                <Button bType="rightArrow"></Button>

                <p>downloadFileIcon</p>
                <Button bType="downloadFileIcon"></Button>
            </div>
            <hr>
            <p>isLoading prop</p>
            <Button bType="normal" :isLoading="true"></Button>
            <Button bType="print" :isLoading="true"></Button>

        </section>

        <section>
            <h2>EmptyTable.vue</h2>
            <EmptyTable title="Titulo" description="Descripcion de tabla vacia" link="/link" linkText="Texto extra" buttonText="Reintentar" buttonType="buttonType" buttonClass="buttonClass" type="type"></EmptyTable>
        </section>

        <section>
            <h2>Message.vue</h2>
            <Message message="Mensaje de alerta de ejemplo, texto de ejemplo para ver como funciona" type="type" buttonLabel="buttonLabel" :loader="true"></Message>
        </section>
        <section>
            <h2>SearchBar.vue</h2>
            <SearchBar showNoResultsMsg="true"></SearchBar>
        </section>
        <section>
            <h2>ShareURL.vue</h2>
            <ShareURL></ShareURL>
        </section>
        <section>
            <h2>TableCreatedBy.vue</h2>
            <TableCreatedBy :item="{item: 'test'}" name="Jesus" surname="Narbona"></TableCreatedBy>
            <TableCreatedBy :item="{item: 'test'}" name="Lluis" surname="Menendez"></TableCreatedBy>
            <TableCreatedBy :item="{item: 'test'}" name="Daniel" surname="Menguiano"></TableCreatedBy>
        </section>

        <section>
            <h2>TableStatuses.vue</h2>
            <TableStatuses status="active" text="Activo"></TableStatuses>
            <TableStatuses status="inactive" text="Inactivo"></TableStatuses>
            <TableStatuses status="pending" text="Inactivo"></TableStatuses>
            <TableStatuses status="ended" text="Finalizado"></TableStatuses>
        </section>

        <section>
            <h2>Tags.vue</h2>
            <Tags :tags="items" :nameTag="'name'" value="id" :limit="false"></Tags>
            <Tags :tags="items" :nameTag="'name'" value="id" :limit="false" :removable="true"></Tags>
        </section>
        <section>
            <h2>TextEditor.vue</h2>
            <p>All</p>
            <TextEditor :value="textEditor" gActions="all" type="full" placeholder="Introduce texto" :limit="1000"></TextEditor>
            <p>Text</p>
            <TextEditor :value="textEditor" gActions="text" type="full" placeholder="Introduce texto" :limit="1000"></TextEditor>
            <p>List</p>
            <TextEditor :value="textEditor" gActions="list" type="full" placeholder="Introduce texto" :limit="1000"></TextEditor>
            <p>Util</p>
            <TextEditor :value="textEditor" gActions="util" type="full" placeholder="Introduce texto" :limit="1000"></TextEditor>
        </section>
        <section>
            <h2>UserTag.vue</h2>
            <br>
            <UserTag userId="K1hNSFNVMy9DczRoMnk3VmpGSTd2dz09"></UserTag>
            <br>
            <UserTag userId="K1k4UmE4QlNGK1QxOVByY3RvM2lGUT09"></UserTag>
        </section>

        <h1>COMPONENTS/INPUT</h1>
        <section>
            <h2>Input.vue</h2>
            <div class="input-blocks">
                <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ type: 'inline', actions: 'text' }" gState="warning"></Input>

                <Input gType="texteditor" v-model="textEditorContent" gPlaceholder="Escribe el titulo..." :gOptions="{ type: 'inline', limit: 250 }"></Input>

                <Input gType="texteditor" v-model="textEditorContentFull" gLabel="Escribe aqui la descripcion:" :gOptions="{}"></Input>

                <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ actions: 'text' }"></Input>

                vista padre -> {{ textEditorContent }}
                <Input gType="texteditor" v-model="textEditorContent" :gOptions="{ actions: ['text', 'italic', 'clear'] }"></Input>
            </div>
            <div class="input-block">
                <h2>Tipos</h2>
            <br />
            <p>Vista padre -> {{ inputText }}</p>
            <Input @blur="blurMethod" :gLabel="$t('popup.issue_alert.title')" v-model="inputText"></Input>
            <br />

            <p>Vista padre -> {{ inputNumber }}</p>
            <Input @blur="blurMethod" gLabel="Tipo NUMBER" gType="number" v-model="inputNumber" :gOptions="{ max: 20, min: -50, step: 5 }"></Input>
            <br />

            <p>Vista padre -> {{ inputDate }}</p>
            <Input @blur="blurMethod" gLabel="Tipo DATE" gType="date" v-model="inputDate"></Input>
            <br />

            <p>Vista padre -> {{ inputTime }}</p>
            <Input @blur="blurMethod" gLabel="Tipo TIME" gType="time" v-model="inputTime"></Input>
            <br />

            <p>Vista padre -> {{ inputDateTime }}</p>
            <Input @blur="blurMethod" gLabel="Tipo DATETIME" gType="datetime" v-model="inputDateTime"></Input>
            <br />

            <p>Vista padre -> {{ inputSelect }}</p>
            <Input @blur="blurMethod" gLabel="Tipo SELECT" gType="select" v-model="inputSelect" :gItems="items" :gOptions="{ multiple: true }"></Input>
            <br />

            <p>Vista padre -> {{ inputAutocomplete }}</p>
            <Input @blur="blurMethod" gLabel="Tipo AUTOCOMPLETE" gType="autocomplete" v-model="inputAutocomplete" :autocompleteValue="inputAutocomplete" :gOptions="{ multiple: true, newInput: true }" :gItems="Object.values(items)"></Input>
            <br />

            <p>Vista padre -> {{ inputTextarea }}</p>
            <Input @blur="blurMethod" gLabel="Tipo textarea" gType="textarea" v-model="inputTextarea" :gItems="items"></Input>
            <br />
            <p>Vista padre -> {{ inputComboBox }}</p>
            <Input @blur="blurMethod" gLabel="Tipo comboBox" :gOptions="{ multiple: true, mandatory: true }" gState="error" gType="combobox" v-model="inputComboBox" :gItems="items"></Input>
            <br />

            <hr />

            <h2>Estados</h2>
            <br />
            <Input gLabel="Nombre normal" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre con error" gState="error" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre con warning" gState="warning" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre done donete" gState="done" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre bloqueado" gState="disabled" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre destacado OwO" gState="highlight" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <hr />

            <h2>Tamaños</h2>
            <br />
            <Input gLabel="Nombre xs" gSize="xs" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre s" gSize="s" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre sm" gSize="sm" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre m" gSize="m" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre ml" gSize="ml" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre l" gSize="l" gMessage="lorem*5 ipsum dolor sit amet y no se como sigue"></Input>
            <Input gLabel="Nombre xl" gSize="xl" gMessage="lorem ipsum dolor sit amet y no se como sigue"></Input>
            <hr />

            <h2>Formatos</h2>
            <br />
            <Input></Input>
            <Input :gOptions="{ mandatory: true }"></Input>
            <Input gHelp="Hola soy un texto de ayuda xDxD"></Input>
            <hr />

            <h2>Otras clases</h2>
            <br />
            <hr />

            <Input gType="datetime" :gMessage="{ default: 'Debes rellenarlo.', error: 'BAIA BAIA' }" gState="error"></Input>
            <Input gLabel="Nombre y apellidos" gType="text" gHelp="Aqui tienes que especificar..." gMessage="Mínimo 8 caracteres"></Input>
            <Input gLabel="Nombre y apellidos" gState="error" :gOptions="{ mandatory: true }" gSize="xs"></Input>
            <Input gLabel="Nombre y apellidos" gState="error" :gOptions="{ mandatory: true }" gHelp="hola soy el texto de ayuda xDxd" gMessage="Hola soy el msg que informa 2fast sobre qué datos se esperan" gSize="xs"></Input>
            </div>
        </section>

        <h1>Popups</h1>
        <section class="popup">
            <Button bType="normal" bLabel="AlertPopup" :bCallback="alertPopup" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="AlertNewDesign" :bCallback="alertNewDesign" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Confirm" :bCallback="confirmPopup" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="DateRange" :bCallback="dateRangePopup" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Delete" :bCallback="showDeleteLocationsGroupModal" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Media" :bCallback="preview" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="PreviewSignature" :bCallback="previewSignature" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="InvitationUser" :bCallback="invitationUser" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="GalleryIcons" :bCallback="openGalleryIcons" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="GalleryTemplates" :bCallback="galleryTemplates" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Numeric" :bCallback="numeric" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Temperature" :bCallback="temperature" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="Sign" :bCallback="sign" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="FreeSign" :bCallback="freeSign" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="PDFDisplayOptions" :bCallback="PDFDisplayOptions" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="MessageWithButton" :bCallback="messageWithButton" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="SelectorHistory" :bCallback="openHistoryPopUp" customClass="btn-secondary"></Button>
            <Button bType="normal" bLabel="TemplateConvertTo" :bCallback="templateConvertTo" customClass="btn-secondary"></Button>

        </section>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Answer from '@/components/ui/Answer.vue' //cType, cId, aId, item, list, isIssueClosed, hasDependency_tpl
import AppVersion from '@/components/ui/AppVersion.vue' //customClass, isHide
import Button from '@/components/ui/Button.vue' //bType, bLable, customClass, bCallback, bCallbackProps,customClass, isLoading, bTypeOfBtn
import EmptyTable from '@/components/ui/EmptyTable.vue' //title, description, link, linkText, buttonText, buttonType, buttonClass, type
import Message from '@/components/ui/Message.vue' //message, type, buttonLabel, loader, hasActions, customClass, portraitDeployed
import SearchBar from '@/components/ui/SearchBar.vue' //showNoResultsMsg
import ShareURL from '@/components/ui/ShareURL.vue'
import TableCreatedBy from '@/components/ui/TableCreatedBy.vue' //item, name, surname, tooltip
import TableStatuses from '@/components/ui/TableStatuses.vue' //status, text
import Tags from '@/components/ui/Tags.vue' //tags, value, nameTag, limit, removable, availableEntities, moreliteral, lessliteral, newInput, referenceObject, backImage, backColor
import TextEditor from '@/components/ui/TextEditor.vue' //value, gActions, type, placeholder, limit
import UserTag from '@/components/ui/UserTag.vue' //userId, size, styleOf, hasAvatar
import Input from '@/components/input/Input.vue' //value, gItems, gType, gLabel, gPlaceholder, gHelp, gState, gSize, gMessage, gOptions, customClass, autocompleteValue, refreshComponent, inputIcon
export default {
    name: 'NewDesignSystem',
    components: {
        Answer,
        AppVersion,
        EmptyTable,
        Message,
        SearchBar,
        ShareURL,
        TableCreatedBy,
        TableStatuses,
        Tags,
        TextEditor,
        UserTag
    },
    data() {
        return {
            items: [
                { id: '111', name: 'Hola' },
                { id: '222', name: 'Adios' },
                { id: '444', name: 'Adeu', tooltipMsg: 'Vengaa Adeu Andreu' },
                { id: '333', name: 'Ciao', tooltipMsg: 'Epaaaaa toma error ahi', tooltipClass: 'tooltip-error' },
                { id: '555', name: 'Holi', tooltipMsg: 'OwO' }
            ],
            textEditor: '',
            textEditorContent: '',
            textEditorContentFull: `
                    <h2>
                      Hi there,
                    </h2>
                    <p>
                      this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of <u>basic text styles</u> you’d probably expect from a text editor. But wait until you see the lists:
                    </p>
                    <ul>
                      <li>
                        That’s a bullet list with one …
                      </li>
                      <li>
                        … or two list items.
                      </li>
                    </ul>
                    <p>
                      Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
                    </p>
                    
                    <p>
                      I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.
                    </p>
                    
            `,
            tooltipText: 'Hola soy un texto de prueba para el tooltip. K pasa xdxd <br> <h2>aber esos titulos xdxd</h2> se ve guay eh jajjaj<b>xd</b>',
            inputText: '',
            inputNumber: '',
            inputDate: '',
            inputTime: '',
            inputDateTime: '',
            inputSelect: '',
            inputAutocomplete: '',
            inputTextarea: '',
            inputComboBox: [],
            executing: false,
            resultImage: false,
        }
    },
    computed: {
        galleryIcons() {
            var items = this.$store.getters['label/getGalleryImages']()
            return items
        },
        loggedUser() {
            return this.userAccess ? this.userAccess : this.$store.getters['loginUser/getLocalEmployee']
        },
    },
    methods: {
        blurMethod() {},
        alertPopup(){
            this.$popup.alert({
                title: this.$t('template.general.popup_validation_error_title'),
                textSave: this.$t('template.general.review'),
                message: this.$t('template.general.popup_validation_error_msg')
            })
        },
        alertNewDesign(){
            const maxItems = 10
            this.$popup.alertNewDesing({
                title: this.$t('supervise.checklists.error_download_excel'),
                message: this.$t('supervise.checklists.error_download_excel_rows', { maxItems: maxItems })
            })
        },
        confirmPopup(){
            this.$popup.confirm({
                message: this.$t('template.general.popup_published_to_draft'),
                textSave: this.$t('template.general.button_save'),
                callSave: function () {
                    return
                },
                callCancel: function () {
                    return
                }
            })
        },
        dateRangePopup(){
            const self = this
            const range = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
            this.$popup.dateRange({
                title: self.$t('nvgt.filters.range.title'),
                dateStart: null,
                dateEnd: null,
                dateRange: range,
                textSave: "Guardar",
                textCancel: "Cancelar",
                callSave: function(pSelf, store) {
                    self.$popup.close()
                }
            })
        },
        showDeleteLocationsGroupModal() {
            var self = this
            const modalMessage = self.$t('groups.form.popup_delete') + "Nombre de ejemplo'?"

            this.$popup.delete({ message: modalMessage, callSave: function () {
                return
            } })
        },
        preview(){
            this.$popup.media({
                image: 'https://andyapp.io/wp-content/uploads/2022/10/logo-oficial_Andy-02.png'
            })
        },
        previewSignature(){
            const img = 'https://andyapp.io/wp-content/uploads/2022/10/logo-oficial_Andy-02.png'
            this.$popup.previewSignature({ image: img })
        },
        invitationUser() {
            const self = this
            self.$popup.invitationUser({
                title: self.$t('user.form.invitation_dashboard_title'),
                message: self.$t('user.form.invitation_dashboard_descrip', {
                    name: "Jesus Narbona",
                    email: "jesus@andyapp.io"
                }),
                textSave: self.$t('user.form.send_invitation_button'),
                inputVal: "test",
                textCancel: self.$t('user.form.in_other_moment'),
                callSave: function () {
                    return
                },
                callCancel: function () {
                    return
                }
            })
        },
        openGalleryIcons() {
            const self = this

            self.$popup.galeryIcons({
                title: this.$t('product.gallery_icons.title'),
                image: self.icon,
                textSave: this.$t('button.save'),
                data: this.galleryIcons,
                textCancel: this.$t('button.cancel'),
                productName: "Product ejemplo",
                callSave: function () {
                    return
                },
                callCancel: function () {
                    return
                }
            })
        },
        galleryTemplates(){
            const self = this
            var data = self.$store.getters['audits/getGalleryTemplates']
            self.$popup.galeryTemplates({
                title: self.$t('public_templates.gallery.title'),
                data: data,
                textCancel: self.$t('button.cancel'),
                callSave: function (tpl) {
                    self.$popup.close()
                },
                callCancel: function () {
                    self.$popup.close()
                }
            })
        },
        temporalTpl(){
            const self = this
            this.$popup.temporalTpl({
                textSave: this.$t('popup.select.text_save'),
                type: 'tplTemporary',
                callSave: function () {
                    self.$popup.close()
                },
                callCancel: function () {
                    self.$popup.close()
                }
            })
        },
        numeric(){
            this.$popup.numeric({
                decimal: true,
                hasNegative: true,
                inputVal: '',
                callSave: function (self, store) {
                    return
                }
            })
        },
        temperature(){
            this.$popup.temperature({
                tempOptions: {
                    mesure: 'ºC',
                    sensorType: 'manual'
                },
                inputVal: '',
                callSave: function (self, store) {
                    return
                }
            })
        },
        sign(){
            this.$popup.sign({
                list: this.$store.getters['employee/getAllEmployees'],
                selectedEl: this.loggedUser,
                callSave: function (self2, store) {
                    return
                }
            })
        },
        freeSign(){
            this.$popup.freeSign({
                callSave: function (self2, store) {
                    return
                }
            })
        },
        PDFDisplayOptions(){
            const self = this
            const reportsQuantity = 1
            self.$popup.PDFDisplayOptions({
                
                config: {
                    quantity: reportsQuantity,
                    tool: 'audit'
                }
            })
        },
        messageWithButton(){
            const self = this
            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: "jesus@andyapp.io",
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },
        openHistoryPopUp(){
            localStorage.setItem('selector-hist', JSON.stringify([{id:"Qm1UWkxSc3BPazZ1QnhIdUUzSjA5UT09",template_name:"\"ALL ITEMS\" con descripciones 👁️",item_name:"SELECT",config:{values:[{v:"option1",c:null},{v:"OptionsdfaFDEWF23",c:null},{v:"si, pero no",c:null}]}}]))
            const history = JSON.parse(localStorage.getItem('selector-hist'))
            this.$popup.selectorsHistory({
                selectorHistory: history,
            })
        },
        templateConvertTo(){
            this.$popup.templateConvertTo({ 
                tool: 1,
                callSave: function () {
                    return
                }
            })
        }
    },
    created() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.design-system-v2{
    padding: 60px;
    overflow-y: auto;
    height: 100%;

    h1{
        @include font-size(xl);
    }
    section {
        @include border-radius(6px);
        background-color: $color-neutral-200;
        margin-bottom: 40px;
        padding: 20px 40px;

        &.negative{
            background-color: $color-neutral-600;
        }

        h2{
            @include font-size(l);
            text-decoration: underline;
        }
        
    }
}
</style>
